import styled from "styled-components"

import { color } from "../../../../atoms/colors"
import Link from "../../../../utils/Link"

export const FooterLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  font-size: ${(props) => (props.legal ? "14px" : "18px")};
  font-family: "Avenir";
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  color: ${color.gray[300]};
  @media (max-width: 576px) {
    font-size: ${(props) => (props.legal ? "14px" : "16px")};
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${color.common.transparent};
  border: none;
`

export const DropdownWrapper = styled.div`
  width: 100%;
  transition: max-height 350ms ease-in-out;
  max-height: ${(props) =>
    props?.activeItem === props?.id ? "1000px" : "0px"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-left: 24px;
  overflow: hidden;
`
export const PlusMinus = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: ${color.common.white};
    transition: transform 0.25s ease-out;
  }
  &:before {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    transform: ${(props) =>
      props?.activeItem === props?.id ? "rotate(90deg)" : "rotate(0deg)"};
  }
  &:after {
    top: 49%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: ${(props) =>
      props?.activeItem === props?.id ? "rotate(180deg)" : "rotate(0deg)"};
  }
`

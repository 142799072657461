import React from "react"

import WhiteLogo from "../../../images/icons/logos-v2/Clickatell-logo-white.svg"
import QASCompliance from "../../../images/qas-logo.png"
import { FooterLink } from "../FooterStandard/styles/FooterStandard.styled"
import { CopyWrapper, VerticalDivider } from "../styles/FooterCopyright.styled"

const CopySection = () => (
  <CopyWrapper>
    <div className="copyright-wrapper">
      <img
        className="clickatell-logo-white"
        width={25}
        height={25}
        src={WhiteLogo}
        alt="clickatell icon"
      />
      <div className="copyright-date">
        © Copyright {new Date().getFullYear()} Clickatell. All rights reserved.
      </div>
    </div>
    <br />
    <div className="legal-and-sitemap">
      <img
        height={40}
        src={QASCompliance}
        alt="QAS International Registered Company"
        className="logo"
      />
      <FooterLink legal to="/legal/">
        Legal Terms
      </FooterLink>
      <VerticalDivider />
      <FooterLink legal to="/sitemap/">
        Sitemap
      </FooterLink>
    </div>
  </CopyWrapper>
)

export default CopySection

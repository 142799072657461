import React, { useState } from "react"

import { BoxColumn } from "../styles/AccordionLinks.styled"

import Drowpdown from "./Drowpdown"

const AccordionLinks = ({ links }) => {
  const [activeItem, setActiveItem] = useState(false)

  return links?.map((item, idx) => (
    <BoxColumn key={item?.internalName}>
      <Drowpdown
        id={idx}
        item={item}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
    </BoxColumn>
  ))
}

export default AccordionLinks

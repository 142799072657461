import React from "react"
import { Container } from "react-bootstrap"

import screenResponsive from "../../../utils/screenResponsive"
import CopySection from "../components/FooterCopyright"

import AccordionLinks from "./components/AccordionLinks"
import ContactUsSection from "./components/ContactUsSection"
import EmailSection from "./components/FooterEmailSection"
import FooterLinks from "./components/FooterLinks"
import {
  BoxColumn,
  BoxGrid,
  BoxRow,
  Divider,
  FooterWrapper,
  FooterBackground,
} from "./styles/FooterStandard.styled"

const FooterStandard = ({ component }) => {
  const isTablet = screenResponsive()
  const isMobile = screenResponsive(768)
  const listOfLinks = component?.menuItem

  return (
    <FooterBackground>
      <Container>
        <FooterWrapper>
          <BoxRow>
            <BoxColumn justify>
              <BoxGrid email>
                <EmailSection />
              </BoxGrid>
              <ContactUsSection hidden={isTablet} />
            </BoxColumn>
            <BoxGrid className="main-links" padding>
              {!isMobile && <FooterLinks links={listOfLinks} />}
              {isMobile && <AccordionLinks links={listOfLinks} />}
            </BoxGrid>
            <ContactUsSection hidden={!isTablet} />
          </BoxRow>
          <Divider />
          <CopySection />
        </FooterWrapper>
      </Container>
    </FooterBackground>
  )
}

export default FooterStandard

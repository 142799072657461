import React from "react"

import { FooterSocialRow } from "../../components/FooterSocialRow"
import { ConnectTitle, SubColumn } from "../styles/ContactUsSection.styled"

import TalkToSalesButton from "./TalkToSalesButton"

const ContactUsSection = ({ hidden }) => (
  <SubColumn hidden={hidden} className="contact-and-socials">
    <TalkToSalesButton />
    <div className="box-column">
      <ConnectTitle className="connect-with-us-title">
        Connect with us
      </ConnectTitle>
      <FooterSocialRow />
    </div>
  </SubColumn>
)

export default ContactUsSection

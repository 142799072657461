import styled from "styled-components"

export const TalkToSalesWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  h3 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  .contact-link {
    text-decoration: none;
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
  }

  .contact-button {
    display: flex;
    padding: 0px;
    color: white;
    background: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    height: 42px;
    width: 270px;
    border-radius: 40px;
    @media (max-width: 992px) {
      width: 122px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .contact-button:hover {
    transition: all 0.15s ease-in-out;
    color: var(--secondary);
    border: 1px solid var(--secondary);
  }
`

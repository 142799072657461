import React from "react"

import {
  Youtube,
  Twitter,
  Facebook,
  LinkedIn,
  Instagram,
  IconXLogo,
} from "../../../utils/V2Icons"
import { FooterSocialCol } from "../FooterStandard/styles/FooterStandard.styled"

export const FooterSocialRow = () => (
  <FooterSocialCol>
    <div className="socials">
      <a
        href="https://www.facebook.com/clickatell-187073743959"
        target="_blank"
        rel="noreferrer"
        aria-label="Facebook"
      >
        <Facebook />
      </a>
      <a
        href="https://twitter.com/Clickatell"
        target="_blank"
        rel="noreferrer"
        aria-label="Twitter"
      >
        <IconXLogo />
      </a>
      <a
        href="https://www.linkedin.com/company/clickatell"
        target="_blank"
        rel="noreferrer"
        aria-label="LinkedIn"
      >
        <LinkedIn />
      </a>
      <a
        href="https://www.instagram.com/clickatell_/"
        target="_blank"
        rel="noreferrer"
        aria-label="Instagram"
      >
        <Instagram />
      </a>
      <a
        href="https://www.youtube.com/clickatell"
        target="_blank"
        rel="noreferrer"
        aria-label="Youtube"
      >
        <Youtube />
      </a>
    </div>
  </FooterSocialCol>
)

import React from "react"

import { BoxColumn, FooterLink } from "../styles/FooterStandard.styled"

const FooterLinks = ({ links }) =>
  links?.map((item) => (
    <BoxColumn key={item?.internalName}>
      <h4 className="link-title">{item?.label}</h4>
      {item?.menuItem?.map((link) => (
        <FooterLink to={link?.url} key={link?.label}>
          {link?.label}
        </FooterLink>
      ))}
    </BoxColumn>
  ))
export default FooterLinks

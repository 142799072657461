import React from "react"

import {
  FooterLink,
  Button,
  DropdownWrapper,
  PlusMinus,
} from "../styles/Dropdown.styled"

const Drowpdown = ({ item, id, activeItem, setActiveItem }) => {
  const handleClick = () => {
    if (activeItem === id) {
      setActiveItem(null)
    } else {
      setActiveItem(id)
    }
  }

  return (
    <>
      <Button onClick={() => handleClick(id)}>
        <h4 className="link-title">{item?.label}</h4>
        <PlusMinus id={id} activeItem={activeItem} />
      </Button>
      <DropdownWrapper id={id} activeItem={activeItem}>
        {item?.menuItem?.map((link) => (
          <FooterLink to={link?.url} key={link?.label}>
            {link?.label}
          </FooterLink>
        ))}
      </DropdownWrapper>
    </>
  )
}

export default Drowpdown

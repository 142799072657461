import React from "react"

import { TalkToSalesWrapper } from "../styles/TalkToSales.styled"

const TalkToSalesButton = () => (
  <TalkToSalesWrapper>
    <h3 className="contact-us-title">Contact us</h3>
    <a
      className="contact-link"
      href="https://www.clickatell.com/contact/contact-sales/"
    >
      <button className="contact-button">Talk to Sales</button>
    </a>
  </TalkToSalesWrapper>
)

export default TalkToSalesButton

import React from "react"
import HubspotForm from "react-hubspot-form"

import Logo from "../../../../images/icons/clickatell-footer-words.svg"
import Img from "../../../../utils/OptimizedImage"
import {
  EmailSubContainer,
  LogoSection,
  LogoWrapper,
} from "../styles/FooterEmailSection.styled"

const EmailSection = () => (
  <LogoSection>
    <LogoWrapper>
      <Img width={170} height={24} src={Logo} alt="Clickatell Footer Logo" />
      <div className="supporting-text">Global Leader in Chat Commerce</div>
    </LogoWrapper>
    <EmailSubContainer>
      <HubspotForm
        className="footer-newsletter-form"
        formId="2a712ee9-614c-4e7a-8808-f5c6b6c0fcc1"
        portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
      />
    </EmailSubContainer>
  </LogoSection>
)

export default EmailSection

import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const CopyWrapper = styled.div`
  gap: 16px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  padding-top: 16px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
  }
  .copyright-wrapper {
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    display: flex;
    gap: 12px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media screen and (min-width: 400px) {
      white-space: nowrap;
    }
  }
  .legal-and-sitemap {
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    .logo {
      padding-right: 10px;
    }
    a {
      color: ${color.common.white};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`
export const VerticalDivider = styled.div`
  height: 12px;
  border-left: 0.5px solid white;
`

import styled from "styled-components"

import { color } from "../../../../atoms/colors"

export const LogoWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const LogoSection = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    width: 100%;
  }

  .supporting-text {
    white-space: nowrap;
    color: ${color.gray[300]};
  }
`
export const EmailSubContainer = styled.div`
  gap: 8px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 992px) {
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
  }

  .hs-email {
    order: 0;
    @media (min-width: 768px) and (max-width: 992px) {
      width: 370px;
    }
  }

  .legal-consent-container {
    @media (min-width: 768px) and (max-width: 992px) {
      order: 2;
    }
  }

  .hs-form {
    gap: 12px;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    label {
      line-height: 24px;
      font-weight: 800;
      font-size: 18px;
    }
  }

  input.hs-button {
    gap: 8px;
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    height: 42px;
    border-radius: 40px;
    background: ${color.primary[200]};
    border: 1px solid ${color.primary[200]};

    @media (min-width: 992px) {
      width: 270px;
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
      position: absolute;
      width: 141px;
      left: 375px;
      top: 32px;
    }
  }

  h4 {
    white-space: nowrap;
  }

  &.hs-fieldtype-text {
    width: 100%;
  }

  div.input {
    margin-right: 0 !important;
    width: 100%;
  }

  input.hs-input {
    gap: 8px;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    padding: 10px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${color.common.white};
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  input[type="checkbox"] {
    width: auto !important;
  }
  input[name="lastname"] {
    display: none;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hs-form-booleancheckbox {
    font-size: 12px;
  }

  .hs-main-font-element {
    padding-top: 2rem;
    color: white;
  }

  .hs-richtext {
    font-size: 14px;
  }

  .form-columns-2 {
    display: flex;
    flex-direction: column;
  }
`

export const EmailInput = styled.input`
  gap: 8px;
  width: 270px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${color.common.white};
  border: 1px solid ${color.gray[300]};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

import styled from "styled-components"

import { color } from "../../../../atoms/colors"

export const ConnectTitle = styled.h3`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 16px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`
export const SubColumn = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  padding-top: 2rem;
  color: white;
  height: auto;
  gap: 16px;

  @media (max-width: 992px) {
    flex-direction: row;
    gap: 116px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
`

import styled, { css } from "styled-components"

import { color } from "../../../../atoms/colors"
import Link from "../../../../utils/Link"

export const FooterBackground = styled.div`
  background: ${color.oxfordBlue[900]};
`
export const FooterWrapper = styled.div`
  margin: auto;
  max-width: 1170px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 96px 0px;
  @media screen and (max-width: 992px) {
    max-width: 768px;
    padding: 72px 0px;
  }
  @media screen and (max-width: 768px) {
    max-width: 506px;
    padding: 40px 0px;
  }
`

export const BoxRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1170px;
  justify-content: space-between;
  gap: 130px;
  margin-bottom: 48px;
  padding: ${(props) => (props.padding ? "96px 0" : "0")};

  @media screen and (max-width: 1200px) {
    flex-direction: row;
    gap: 90px;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: unset;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`
export const BoxColumn = styled.div`
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 270px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${(props) => (props.justify ? "space-between" : "")};
  @media (max-width: 992px) {
    max-width: unset;
  }

  li.footer-link {
    text-decoration: none;
    list-style: none;
    color: white;
  }

  h4.link-title {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: white;
  }
`

/**
 * BoxGrid - styling for the site links.
 */
export const BoxGrid = styled.div`
  width: 100%;
  display: ${(props) => (props.hidden ? "none" : "grid")};
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  grid-auto-columns: auto;
  row-gap: 2rem;
  &.main-links {
    @media (max-width: 768px) {
      padding: 64px 0px 40px 0px;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset;
    grid-auto-flow: unset;
    grid-auto-columns: unset;
    row-gap: unset;
  }

  ${(props) =>
    props.email
      ? css`
          @media screen and (max-width: 576px) {
            display: flex;
            flex-direction: column;
          }
        `
      : css`
          @media screen and (max-width: 375px) {
            display: flex;
            flex-direction: column;
          }
        `}
`
export const FlexSocials = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 576px) {
    flex-direction: column;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid gray;
`

export const FooterLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  font-size: ${(props) => (props.legal ? "14px" : "18px")};
  font-family: "Avenir";
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  color: ${color.gray[300]};
`

export const FooterSocialCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 991.98px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 26px;
  }

  .bottom-text {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 12px;
    color: ${color.gray[300]};
    font-weight: 400;
  }

  .link {
    color: white;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;
  }

  .socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    @media screen and (min-width: 400px) {
      flex-wrap: nowrap;
    }
  }

  .copy-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 576px) {
      width: 90%;
    }
  }
`
export const ContactUsMobile = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
`

import styled from "styled-components"

import { color } from "../../../../atoms/colors"

export const BoxColumn = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${(props) => (props.justify ? "space-between" : "")};
  padding: 16px 0px;
  border-bottom: 1px solid ${color.gray[700]};
  li.footer-link {
    text-decoration: none;
    list-style: none;
    color: white;
  }

  h4.link-title {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: white;
    margin-bottom: 0px;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
`
